.w1200{
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 25px;
}

.whiteBox{
    box-shadow: 0 10px 20px rgba(11, 123, 217, 0.05);
}

.group{
    display: flex;
    width: 100%;
    margin-bottom: 5px;
}

.input_style{
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 10px;
    height: 30px;
    font-size: 1rem;
}

.ml-50{
    margin-left: 50px;
}

.ck.ck-editor{width: 100%;}
.ck-editor__editable {min-height: 500px;}
.ck-editor__editable p {margin:0;}
.contentArea p {margin: 0}

.upload-fileBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 3px dotted #ACB5CF;
    border-radius: 20px;
    flex-direction: column;
}